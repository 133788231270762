* {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
}

body {
	background-color: #263238;
	margin: 0;
	font-family: 'Noto', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	display: flex;
	flex-direction: column;
	max-height: 100%;
}
